import * as React from "react"

import DynamicGlobalComponent from "../components/dynamicGlobalComponent"
import useStoryblok from "../../src/lib/storyblok"

import StoryblokHelmet from "../components/internal/StoryblokHelmet"
import Breadcrumb from "../components/internal/Breadcrumb"
import NewsPost from "../components/internal/NewsPost"
import { useSiteHeader } from "../hooks/useSiteHeader"
import { useSiteFooter } from "../hooks/useSiteFooter"

const NewsArticle = ({ pageContext, location }) => {
  let story = pageContext.story
  let header = useSiteHeader(),
      footer = useSiteFooter()
  story = useStoryblok(story, location)
  let { meta } = pageContext.story.content

  if(!meta) { 
    meta = {title: story.name}
  } else if (!meta.title) {
    meta.title = story.name
  }

  let headComponents = [],
      footComponents = []
  // Header
  if(header && header.body) {
    for(let prop in header.body) {
      let blok = header.body[prop]
      headComponents.push(<DynamicGlobalComponent blok={blok} disableEdit={true} key={blok["_uid"]} />)
    }
  }
  if(story.content) {
    // Breadcrumb
    headComponents.push(<Breadcrumb pagePath={"/news"} key="page_breadcrumb" />)
  }
  if(footer && footer.body) {
    // Footer
    for(let prop in footer.body) {
      let blok = footer.body[prop]
      footComponents.push(<DynamicGlobalComponent disableEdit={true} blok={blok} key={blok["_uid"]} />)
    }
  }
  const newsPostData = story.content
  return (
    <div className="body-wrapper dark-wrapper">
      <StoryblokHelmet meta={meta} />
      <title>{story.name}</title>
      {headComponents}
      <div className="container" style={{paddingTop: 50}}>
        <NewsPost 
          header_text={newsPostData.header_text}
          header_image={newsPostData.header_image}
          header_image_link={newsPostData.header_image_link}
          posted_date={newsPostData.posted_date}
          news_content={newsPostData.news_content} />
        <br/><br/><br/><br/>
      </div>
      {footComponents}
    </div>
  )
}

export default NewsArticle