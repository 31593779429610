import { useEffect, useState } from "react";
import StoryblokClient from 'storyblok-js-client'
import config from '../../gatsby-config'
const sbConfig = config.plugins.find((item) => item.resolve === 'gatsby-source-storyblok')

const sbClient = new StoryblokClient({
  accessToken: sbConfig.options.accessToken,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

export default function useStoryblok(originalStory, location) {
  let [story,setStory] = useState(originalStory)

  if(story && typeof story.content == "string") {
    story.content = JSON.parse(story.content)
  }

  function initEventListeners() {
    const { StoryblokBridge } = window

    if(typeof StoryblokBridge !== 'undefined') { 
      const storyblokInstance = new StoryblokBridge({
        resolve_relations: ['ContactPage.contact'] // Make sure to update gatsbyconfig as well AND draft api call on line 44
      })

      storyblokInstance.on(["published","change"], (event)=>{
        window.location.reload(true)
      })

      storyblokInstance.on(["input"],(event)=>{
        if(event.story) {
          setStory(event.story)
        }
      })

      storyblokInstance.on('enterEditmode',(event)=>{
        // Load draft version on initial view of the page?
        sbClient
          .get(`cdn/stories/${event.storyId}`,{
            version: 'draft',
            resolve_relations: ['ContactPage.contact'] // Make sure to update gatsbyconfig as well AND instance init on line 26
          })
          .then(({ data })=>{
            if(data.story) {
              setStory(data.story)
            }
          })
          .catch((error)=>{
            console.error(error)
          })
      })
    }
  }

  function addBridge(callback) {
    const existingScript = document.getElementById("storyblokBridge")
    if(!existingScript) {
      const script = document.createElement("script")
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js"
      script.id = "storyblokBridge"
      document.body.appendChild(script)
      script.onload = ()=>{callback()}
    } else {
      callback()
    }
  }

  useEffect(()=>{
    if(location.search.includes("_storyblok")) {
      addBridge(initEventListeners)
    }
  },[])
  
  return story
}
