import React from "react"
import { resolveRichText } from "../../hooks/useRichText"
import StoryblokImage from "../internal/StoryblokImage"
import StoryblokLink from "../internal/StoryblokLink"
import { isLinkDefined } from "../../utils/Link"

export default function NewsPost({
  header_text,
  header_image,
  header_image_link,
  posted_date,
  news_content,
}) {
  let newsDate = new Date(posted_date).toLocaleDateString("en-US", {month: 'long', day: 'numeric', year: 'numeric'})
  return (
    
    <div className="classic-blog single">
      <div className="post format-image">
        {header_image ? (
          <div>
            { isLinkDefined(header_image_link) ? (
              <StoryblokLink storyblokLinkData={header_image_link}>
                <figure className="icon-overlay medium icn-link main"><StoryblokImage sbImageData={header_image} /></figure>
              </StoryblokLink>
            ) : (
              <figure className="icon-overlay medium icn-link main"><StoryblokImage sbImageData={header_image} /></figure>
            )}
          </div>
        ) : null}
        <div className="post-content image-caption">
          <h2 className="post-title">{header_text}</h2>
          <div className="meta"><span className="date">{newsDate}</span></div>
          <div>{resolveRichText(news_content)}</div>
        </div>
      </div>
    </div>
  );
}
