import React from "react"
import { withPrefix } from "gatsby-link"
import { Helmet } from "react-helmet"


function StoryblokHelmet({ meta }) {
  if(!meta) { return null }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {meta.title ? <title>{meta.title}</title> : null}
      {meta.description ? <meta name="description" content={meta.description} /> : null}
      {meta.og_image ? <meta name="og:image" content={meta.og_image} /> : null}
      {meta.og_title ? <meta name="og:title" content={meta.og_title} /> : null}
      {meta.og_description ? <meta name="og:description" content={meta.og_description} /> : null}
      {meta.twitter_image ? <meta name="twitter:image" content={meta.twitter_image} /> : null}
      {meta.twitter_title ? <meta name="twitter:title" content={meta.twitter_title} /> : null}
      {meta.twitter_description ? <meta name="twitter:description" content={meta.twitter_description} /> : null}
      <script src={withPrefix('scripts/jquery.min.js')} type="text/javascript" />
      <script src={withPrefix('scripts/bootstrap.min.js')} type="text/javascript" />
      <script src={withPrefix('scripts/html5shiv.js')} type="text/javascript" />
      {/* <script src={withPrefix('scripts/jquery.easytabs.min.js')} type="text/javascript" /> */}
      {/* <script src={withPrefix('scripts/jquery.fancybox.pack.js')} type="text/javascript" /> */}
      {/* <script src={withPrefix('scripts/jquery.fitvids.js')} type="text/javascript" /> */}
      <script src={withPrefix('scripts/jquery.isotope.min.js')} type="text/javascript" />
      <script src={withPrefix('scripts/jquery.slickforms.js')} type="text/javascript" />
      <script src={withPrefix('scripts/jquery.sticky.js')} type="text/javascript" />
      <script src={withPrefix('scripts/jquery.themepunch.plugins.min.js')} type="text/javascript" />
      <script src={withPrefix('scripts/jquery.themepunch.revolution.min.js')} type="text/javascript" />
      <script src={withPrefix('scripts/retina.js')} type="text/javascript" />
      <script src={withPrefix('scripts/scripts.js')} type="text/javascript" />
      <script src={withPrefix('scripts/twitter-bootstrap-hover-dropdown.min.js')} type="text/javascript" />
    </Helmet>
  )
}

export default StoryblokHelmet