import * as React from "react"
import useStoryblok from "../../src/lib/storyblok"

import StandardPageLayout from "../components/layout/StandardPageLayout"
import ContactPageLayout from "../components/layout/ContactPageLayout"
import NewsArticle from "../templates/NewsArticle"

import { useSiteHeader } from "../hooks/useSiteHeader"
import { useSiteFooter } from "../hooks/useSiteFooter"
import DynamicGlobalComponent from "../components/dynamicGlobalComponent"
import { Helmet } from "react-helmet"

const ProductionPage = () => {
  const header = useSiteHeader(),
        footer = useSiteFooter()

  let headComponents = [],
      footComponents = []

  // Header
  if(header && header.body) {
    for(let prop in header.body) {
      const blok = header.body[prop]
      headComponents.push(<DynamicGlobalComponent blok={blok} key={blok["_uid"]} disableEdit={true} />)
    }
  }
  // Footer
  if(footer && footer.body) {
    for(let prop in footer.body) {
      const blok = footer.body[prop]
      footComponents.push(<DynamicGlobalComponent blok={blok} key={blok["_uid"]} disableEdit={true} />)
    }
  }
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found</title>
        <meta name="robots" content="noindex"/>
      </Helmet>
      {headComponents}
      <div className="dark-wrapper">
        <div className="container inner">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="text-center">404 - Page Not Found</h1>
              <h2>Sorry, the page you are looking for couldn't be found. Please recheck the URL or try one of these helpful links: </h2>
              <ul>
                <li>Visit our <a href="/about-wri/employee-directory">contact page and employee directory</a>
                </li>
                <li>Visit one of our research areas:
                  <ul>
                    <li>
                      <a href="/sustainable-and-emerging-technologies">Sustainable Emerging Technologies (SET)</a>
                    </li>
                    <li>
                      <a href="/asphalt-and-petroleum-technology">Asphalt &amp; Petroleum Technology (APT)</a>
                    </li>
                    <li>
                      <a href="/advanced-carbon-materials">Advanced Carbon Materials (ACM)</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {footComponents}
    </div>
  )
}

function NotFoundPage({ location }) {
  let pageComponent = null
  let story = useStoryblok(null, location)

  if(story) {
    const storyData = story.story ? story.story : story
    switch(storyData.content.component) {
      case "ContactPage":
        pageComponent = (
          <ContactPageLayout
            pageName={storyData.name}
            mainContent={storyData.content.main_content}
            contactData={storyData.content.contact}
            contactContent={storyData.content.contact_content}
            lowerContent={storyData.content.lower_content} />
        )
        break
      case "StandardPage":
        pageComponent = (
          <StandardPageLayout
            pageName={storyData.name}
            mainContent={storyData.content} />
        )
        break
      case "news article":
        pageComponent = (
          <NewsArticle
           pageContext={{story}}
           location={location} />
        )
        break
      default:
        console.warn(`Missing Page Type [${storyData.content.component}]`)
        pageComponent = (<p>Storyblok has not been configured to render this type of page yet.</p>)
        break
    }
  } else {
    console.warn('404:No Story Content',story);
  }

  return (
    <React.Fragment>
      { pageComponent ? pageComponent : <ProductionPage />}
    </React.Fragment>
  )
}

export default NotFoundPage