console.log(`[Storyblok] Node environment set to ${process.env.GATSBY_STORYBLOK_VERSION}`)
console.log(process.env.GATSBY_BASE_URL)

module.exports = {
  pathPrefix: "/",
  siteMetadata: {
    siteUrl: process.env.GATSBY_BASE_URL,
    title: "Western Research Institute",
    titleTemplate: `%s · WRI`,
    description: "",
    url: "",
    image: ""
  },
  plugins: [
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sharp",
    "gatsby-transformer-sharp",{
      resolve: "gatsby-plugin-netlify",
      options: {
        mergeSecurityHeaders: true,
        headers: {
          "/*": [
              "Content-Security-Policy: frame-ancestors 'self' https://*.storyblok.com/",
              "X-Frame-Options: ALLOW FROM https://app.storyblok.com/"
          ]
        }
      }
    },
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "cN9y2PP3QaNxeTaevIECBQtt",
        version: process.env.GATSBY_STORYBLOK_VERSION === "production" ? "published" : "draft",
        homeSlug: "home",
        languages: ['en'],
        resolveRelations: ['ContactPage.contact'], // Make sure this matches the storyblok.js draft api call as well
        includeLinks: true
      }
    },
    {
      resolve: "gatsby-plugin-google-fonts",
      options: {
        fonts: [
          "Raleway:400,300,500,600,700,800,900"
        ]
      }
    }
  ],
};