import React from "react"

import { useSiteHeader } from "../../hooks/useSiteHeader"
import { useSiteFooter } from "../../hooks/useSiteFooter"
import DynamicComponent from "../dynamicComponent"
import DynamicGlobalComponent from "../dynamicGlobalComponent"
import ScrollToTop from "../internal/ScrollToTop"

import Breadcrumb from "../internal/Breadcrumb"
import ContactInfo from "../widgets/ContactInfo"

function ContactPageLayout({ pagePath, backgroundColor, contactData, mainContent, contactContent, lowerContent, breadcrumbAdditionalLabel }) {
  const header = useSiteHeader(),
        footer = useSiteFooter()
  let headComponents = [],
      contactComponents = [],
      mainComponents = [],
      lowerComponents = [],
      footComponents = []
  
  // Header
  if(header && header.body) {
    for(let prop in header.body) {
      const blok = header.body[prop]
      headComponents.push(<DynamicGlobalComponent blok={blok} key={blok["_uid"]} disableEdit={true} />)
    }
  }
  // Breadcrumb
  headComponents.push(<Breadcrumb pagePath={pagePath} key="page_breadcrumb" additionalLabel={breadcrumbAdditionalLabel} />)
  if(mainContent) {
    // Main Content
    for(let prop in mainContent) {
      const blok = mainContent[prop]
      mainComponents.push(<DynamicComponent blok={blok} key={blok["_uid"]} />)
    }
  }
  if(contactContent) {
    // Contact Column Content
    for(let prop in contactContent) {
      const blok = contactContent[prop]
      contactComponents.push(<DynamicComponent blok={blok} key={blok["_uid"]} />)
    }
  }
  if(lowerContent) {
    // Lower Content
    for(let prop in lowerContent) {
      const blok = lowerContent[prop]
      lowerComponents.push(<DynamicComponent blok={blok} key={blok["_uid"]} />)
    }
  }
  if(footer && footer.body) {
    // Footer
    for(let prop in footer.body) {
      const blok = footer.body[prop]
      footComponents.push(<DynamicGlobalComponent blok={blok} key={blok["_uid"]} disableEdit={true} />)
    }
  }

  if(contactData && contactData.content) {
    contactData = contactData.content
  }

  return (
    <div className={`body-wrapper${backgroundColor ? ` ${backgroundColor}`: " dark-wrapper"}`}>
      {headComponents}
      <div className="container inner">
        <div className="row">
          <div className="col-lg-8">
            {mainComponents}
          </div>
          <div className="col-lg-4">
            {contactData ? <h3>For more information, contact:</h3> : null}
            {contactData ? <ContactInfo contactData={contactData} /> : null}
            { contactComponents && contactComponents.length > 0 ? <div>{ contactComponents }</div> : null}
          </div>
        </div>
        {lowerComponents && lowerComponents.length > 0 ? (
          <div>
            <div className="row">
              <div className="col-sm-12">
                {lowerComponents}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {footComponents}
      <ScrollToTop />
    </div>
  )
}

export default ContactPageLayout